<template>
  <div>
    <div class="content">
      <div class="prompt">
        <img src="../../assets/images/LTicon.png" alt="">
        <div class="promptText">意见反馈</div>
      </div>
      <div class="searchContent">
        <div class="search">
          <div class="formList">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="反馈人姓名：">
                <el-input clearable v-model="formInline.FUserName" placeholder="请输入姓名查询" class="custom-input"></el-input>
              </el-form-item>
              <el-form-item label="反馈意见：">
                <el-input clearable v-model="formInline.opinion" placeholder="请输入内容查询" class="custom-input"></el-input>
              </el-form-item>
              <el-form-item label="反馈开始时间：">
                <el-date-picker :append-to-body="false" type="date" placeholder="选择开始时间" value-format="yyyy-MM-dd"
                  class="custom-input" v-model="formInline.beginTime" style="width: 100%;"></el-date-picker>
              </el-form-item>
              <el-form-item label="反馈结束时间：">
                <el-date-picker :append-to-body="false" type="date" placeholder="选择结束时间" :picker-options="pickerOptions"
                  value-format="yyyy-MM-dd" class="custom-input" v-model="formInline.endTime"
                  style="width: 100%;"></el-date-picker>
              </el-form-item>
              <el-form-item label="回复开始时间：">
                <el-date-picker :append-to-body="false" type="date" placeholder="选择回复开始时间" value-format="yyyy-MM-dd"
                  class="custom-input" v-model="formInline.replyBeginTime" style="width: 100%;"></el-date-picker>
              </el-form-item>
              <el-form-item label="回复结束时间：">
                <el-date-picker :append-to-body="false" type="date" placeholder="选择回复结束时间"
                  :picker-options="pickerOptionsReply" value-format="yyyy-MM-dd" class="custom-input"
                  v-model="formInline.replyEndTime" style="width: 100%;"></el-date-picker>
              </el-form-item>
              <el-form-item>
              </el-form-item>
            </el-form>
          </div>
          <div class="newView">
            <el-button  type="primary" @click="onSubmit" icon="el-icon-search" class="custom-button">查询</el-button>

            <el-button :class="isStringInArray('btnIncreaseFeedback') ? '' : 'btnShowAuthority'" type="primary" @click="openViewDialog" icon="el-icon-plus" class="custom-button">新建意见</el-button>
          </div>
        </div>
      </div>
      <div class="table">
        <el-table v-loading='dataLoading' element-loading-text="加载中" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.3)" border :data="tableData" style="width: 100%"
          class="custom-table" :cell-style="{ color: '#AED6FF', fontSize: '12px' }"
          :header-cell-style="{ color: '#e8f4ff', fontSize: '14px' }">
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="tableText">
                  {{ dataLoading ? '' : '暂无数据' }}
                </div>
              </div>
            </div>
          </div>
          <el-table-column align="center" label="序号" type="index" width="100">
          </el-table-column>
          <el-table-column align="center" prop="fuserName" label="反馈人姓名" width="120">
          </el-table-column>
          <el-table-column align="center" prop="opinion" label="意见反馈描述" :fluid="true" min-width="300">
            <template slot-scope="scope">
              <template v-if="isOverflow(scope.row.opinion)">
                <el-tooltip :content="scope.row.opinion" placement="top" :popper-class="'custom-tooltip'">
                  <div class="ellipsis">{{ scope.row.opinion }}</div>
                </el-tooltip>
              </template>
              <template v-else>
                <div class="ellipsis">{{ scope.row.opinion }}</div>
              </template>
            </template>

          </el-table-column>
          <el-table-column align="center" prop="feedbackTime" label="反馈时间" width="180">
          </el-table-column>
          <el-table-column align="center" prop="ruserName" label="回复人姓名" width="150">
          </el-table-column>
          <el-table-column align="center" prop="reply" label="回复描述" :fluid="true" min-width="300">
            <template slot-scope="scope">
              <template v-if="isOverflow(scope.row.reply)">
                <el-tooltip :content="scope.row.reply" placement="top" :popper-class="'custom-tooltip'">
                  <div class="ellipsis">{{ scope.row.reply }}</div>
                </el-tooltip>
              </template>
              <template v-else>
                <div class="ellipsis">{{ scope.row.reply }}</div>
              </template>
            </template>

          </el-table-column>
          <el-table-column align="center" prop="replyTime" label="回复时间" width="180">
          </el-table-column>
          <el-table-column align="center" prop="data" fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button :class="isStringInArray('btnReplyFeedback') ? '' : 'btnShowAuthority'" v-show="!scope.row.reply" @click="replyDialog(scope.row)" type="text">回复</el-button><span
                v-show="!scope.row.reply">|</span>
              <el-button :class="isStringInArray('btnDeleteFeedback') ? '' : 'btnShowAuthority'"  @click="replyDialog1(scope.row)" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <div class="btn" @click="startHome">首页</div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next, sizes,slot"
          :prev-text="prevButtonText" :next-text="nextButtonText" prev-button-class="custom-prev-button"
          next-button-class="custom-next-button" :total="total">
        </el-pagination>
        <div class="btn endBtn" @click="endHome">尾页</div>
        <div class="paginationEnd">
          <div>跳</div>至
          <el-input size="mini" @change="paginationEnd" class="paginationEndIpt" @input="handleEdit"
            v-model.number="paginationEndIpt"></el-input>
          <div>页</div>
          <div class="btn confirm" @click="jumpPage">确定</div>
        </div>
      </div>
    </div>

    <!-- 新建意见弹窗 -->
    <el-dialog @close="handleClose" title="新建意见" :visible.sync="centerDialogVisible" width="30%">

      <div class="newCenter">
        <!-- <span class="newCenterText">反馈描述：</span>
        <el-input show-word-limit maxlength="200" type="textarea" :rows="6" placeholder="请输入反馈描述"
          v-model="newCenter.opinion" class="custom-input-textarea">
        </el-input> -->
        <el-form :model="newCenter" ref="newCenter" :rules="rules" label-width="100px" class="demo-ruleForm">
          <el-form-item label="意见:" prop="opinion">
            <el-input class="customInput" show-word-limit maxlength="200" type="textarea" :rows="6" placeholder="请输入反馈描述"
              v-model.trim="newCenter.opinion"></el-input>
          </el-form-item>
        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">

        <el-button class="dialogBtn" @click="centerDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="addDialogVisible('newCenter')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 回复意见弹窗 -->
    <el-dialog @close="handleClose1" title="回复" :visible.sync="replyDialogVisible" width="30%">

      <div class="newCenter">
        <!-- <span class="newCenterText">回复描述：</span>
        <el-input show-word-limit maxlength="200" type="textarea" :rows="6" placeholder="请输入回复描述"
          v-model="formReply.reply" class="custom-input-textarea">
        </el-input> -->
        <el-form :model="formReply" ref="formReply" :rules="rules1" label-width="100px" class="demo-ruleForm">
          <el-form-item label="回复:" prop="reply">
            <el-input class="customInput" show-word-limit maxlength="200" type="textarea" :rows="6" placeholder="请输入回复描述"
              v-model.trim="formReply.reply"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">

        <el-button class="dialogBtn" @click="replyDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="replyBtn('formReply')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="centerDialogVisible1" width="20%">
      <div class="deleteCenter">
        确定删除该条信息?
      </div>

      <span slot="footer" class="dialog-footer">
        <!-- <div class="">取消</div>
    <div class="">确定</div> -->
        <el-button class="dialogBtn" @click="centerDialogVisible1 = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="deleteTableList">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import demo from "./demo.vue"
import { feedBookList, feedBookAdd, feedBookDelete } from '../../api/dailyWork.js'


export default {
  name: 'Feedback',
  data() {
    return {
      formInline: {
        FUserName: '',//名称
        opinion: '',//反馈意见
        beginTime: null,//创建时间
        endTime: null,//结束时间
        replyBeginTime: null,//回复开始时间
        replyEndTime: null,//回复结束时间
      },
      tableData: [],
      centerDialogVisible: false,
      centerDialogVisible1: false,
      replyDialogVisible: false,
      prevButtonText: '上一页',
      nextButtonText: '下一页',
      paginationEndIpt: '',
      pageSize: 10,
      currentPage: 1,
      total: 40,
      newCenter: {
        fuserId: 1,
        opinion: ''
      },
      formReply: {
        ruserId: 3,
        id: '',
        reply: '',
      },
      dataLoading: false,

      rules: {
        opinion: [
          { required: true, message: '请输入反馈描述', trigger: 'blur' },
        ]
      },
      rules1: {
        reply: [
          { required: true, message: '请输入回馈描述', trigger: 'blur' },
        ]
      },
      authorityListBtn:[]

    };
  },
  // components:{demo},

  mounted() {

  },
  created() {
    this.$store.commit('increment', '日常办公')
    this.$store.commit('selectChild', '意见反馈')
    this.getList()
    console.log("带出去哦粗浅凑",sessionStorage.getItem('roleInfo'));
    this.authorityListBtn=sessionStorage.getItem('roleInfo')
    
  },
  computed: {
    pickerOptions() {
      const self = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(self.formInline.beginTime);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        }
      };
    },
    pickerOptionsReply() {
      const self = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(self.formInline.replyBeginTime);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        }
      };
    },

  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "currentPage": {
      // 执行方法
      handler(newValue, oldValue) {
        this.paginationEndIpt = newValue;
      },
      immediate: true, // 第一次改变就执行
    },
  },

  methods: {
    isStringInArray(str) {
    if(this.authorityListBtn){

      let list= this.authorityListBtn.includes(str);
      return list
    }else{
      return false
    }
    },
    isOverflow(content) {
      if (content) {
        const lineHeight = 16;
        const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
        return numLines > 3;
      }
      // console.log("====>",numLines);
    },
    //关闭弹窗
    handleClose() {
      this.newCenter = this.$options.data().newCenter
      this.formReply = this.$options.data().formReply
      this.$refs.newCenter.clearValidate();
    },
    handleClose1() {
      this.newCenter = this.$options.data().newCenter
      this.formReply = this.$options.data().formReply
      this.$refs.formReply.clearValidate();
    },
    deleteTableList() {
      feedBookDelete(this.formReply.id).then(res => {
        if (res.code == 200) {
          this.getList()
          this.formReply = this.$options.data().formReply
          this.centerDialogVisible1 = false
          this.$message({
            message: res.message,
            type: 'success',
            duration: 3000,
            customClass: "messageText",
          });
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })
    },
    //
    //回复弹窗确定按钮
    replyBtn(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          feedBookAdd(this.formReply).then(res => {

            if (res.code == 200) {
              this.getList()
              this.formReply = this.$options.data().formReply
              this.replyDialogVisible = false
              this.$message({
                message: res.message,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
            } else {
              this.$message({
                message: res.message,
                type: 'error',
                duration: 3000,
                customClass: "messageText",
              });
            }
          })
        } else {
          return false
        }
      });
    },
    //新增
    addDialogVisible(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          feedBookAdd(this.newCenter).then(res => {
            if (res.code == 200) {
              this.getList()
              this.newCenter = this.$options.data().newCenter
              this.centerDialogVisible = false
              this.$message({
                message: res.message,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
            } else {
              this.$message({
                message: res.message,
                type: 'error',
                duration: 3000,
                customClass: "messageText",
              });
            }
          })
        } else {
          return false
        }
      });
    },
    //列表查询
    getList() {
      this.dataLoading = true
      let obj = {
        ...this.formInline,
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }
      feedBookList(obj).then(res => {
        this.dataLoading = false
        if (res.code == 200) {
          console.log("====>", res);
          this.total = res.total

          this.tableData = res.data
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })
    },
    //回复
    replyDialog(el) {
      console.log("========>", el);
      this.formReply.id = el.id
      this.replyDialogVisible = true
    },
    //shannchu 
    replyDialog1(el) {
      console.log("========>", el);
      this.formReply.id = el.id
      this.centerDialogVisible1 = true
    },
    //查询列表
    onSubmit() {
      // console.log('submit!');
      // console.log(this.formInline);
      this.currentPage = 1
      this.getList()
    },

    //打开意见弹窗
    openViewDialog() {
      this.centerDialogVisible = true
    },
    openViewDialog1() {
      this.centerDialogVisible1 = true
    },
    //分页相关function
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.paginationEnd()
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.paginationEndIpt = val
      console.log(`当前页: ${val}`);
      console.log(this.currentPage);
      this.getList()
    },
    //首页
    startHome() {
      this.currentPage = 1
      this.getList()

    },
    //尾页
    endHome() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.currentPage = lastPage;
      this.getList()
    },
    //输入校验
    handleEdit(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.paginationEndIpt = value
      // console.log('value===>',value);
    },
    //页数输入时
    paginationEnd() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.paginationEndIpt = this.paginationEndIpt < lastPage ? this.paginationEndIpt : lastPage
    },
    //跳转到xx页
    jumpPage() {
      this.currentPage = this.paginationEndIpt
      this.getList()
      // this.paginationEndIpt=''
    },





  },
};
</script >
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  min-height: 781px;
  background: url(../../assets/images/rightBG.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  overflow: auto;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
}

.promptText {
  margin-left: 10px;
}

.searchContent {
  margin: 11px 25px 16px 26px;
}

.search {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 14px 26px 23px 20px;
  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  display: flex;
  margin-bottom: 10px;

}

.newCenterText {
  width: 100px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
}

.custom-input-textarea ::placeholder {
  color: #667597;
}

.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}

/* .newCenterName{
  width: 100px;
  color: #C9D4D4;
} */
.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

/* 日期选择器 */
::v-deep .el-picker-panel {
  background: #041C4C;
  color: #aed6ff;
  border-color: #041C4C;
}

::v-deep .el-date-picker__header-label {
  color: #aed6ff;
}

::v-deep .el-picker-panel__icon-btn {
  color: #aed6ff;
}

::v-deep .el-date-table th {
  color: #aed6ff;
  border-color: #aed6ff;
}

::v-deep .el-checkbox {
  padding: 0px 20px 0px 10px;
  border-radius: 2px;
  border: 1px solid #09295b;
  color: #aed6ff;
  background-color: #041c4c;
}

::v-deep .is-checked {
  /* border: 1px solid #176ad2; */
  background-color: #041c4c;
  color: #aed6ff;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #aed6ff;
}

::v-deep .el-input-group__append {
  background: #143168;
  color: #fff;
  border: 0;
  cursor: pointer;
}

::v-deep .el-tag.el-tag--info {
  background: #03173C;
  color: #aed6ff;
  border: 1px solid #083B7A;
}

::v-deep .selected {
  background: #041C4C;
}


.custom-border {
  border: none;

}

.custom-border>>>.el-textarea__inner {
  border: 0px;
}

.dictDesc {
  border: 1px solid #06224e;
}

::v-deep .el-upload--picture-card {
  margin: 0 15px 15px 15px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #143168;
  border: 1px solid #143168;
  border-radius: 3px;
}

::v-deep form.el-form.demo-ruleForm {
  width: 100%;
}

::v-deep .el-date-table .disabled div {
  background: #143168;
  color: #aed6ff;
}
.btnShowAuthority{
  display: none;
}
</style>
<style>
.custom-tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
}

</style> 